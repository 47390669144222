export var recruitmentDispositionReasonsBulkAssignment = {
  countForReasonId(id) {
    return $('tr.application.selected select.disposition_reasons').filter(function() {
      var val = $(this).val();
      return val && val.indexOf(id) > -1;
    }).length;
  },

  currentRatioForReasonId(id) {
    var on = this.countForReasonId(id);
    var out_of = $('tr.application.selected').length;

    return {on: on, off: out_of-on, out_of: out_of, all_on: on > 0 && on == out_of, all_off: on == 0};
  },

  updateStateForReasonId(id) {
    this.updateStateInReasonElement($('.reason[data-reason-id=' + id + ']'));
  },

  updateStateInReasonElement(el, to) {
    el = $(el).closest('.reason');
    var id = el.attr('data-reason-id');
    var ratio = this.currentRatioForReasonId(id);
    var was = ratio.all_off ? 'off' : (ratio.all_on ? 'on' : 'indeterminate');

    if (to === true || to === false) {
      var is = to ? 'on' : 'off';
      var checked = to;
      var ind = false;
    } else {
      var is = was;
      var checked = ratio.all_on;
      var ind = !ratio.all_on && !ratio.all_off;
    }

    el.find('input.was').val(was);
    el.find('input.is:not(:checkbox)').val(is);

    var state = el.find('.state');
    state.prop('checked', checked);
    state.prop('indeterminate', ind);

    this.updateCountsInReasonElement(el, ratio.off, ratio.on);
  },

  updateCountsInReasonElement(el, ifAdded, ifRemoved) {
    this.updateCountInReasonElement(el.find('.if-added'), ifAdded);
    this.updateCountInReasonElement(el.find('.if-removed'), ifRemoved);
  },

  updateCountInReasonElement(ifEl, count) {
    ifEl.find(' .count').text(count);
    ifEl.find(' .plural').text(count == 1 ? '' : 's');
    ifEl.toggleClass('hidden', count == 0);
  },

  updatePredictionInReasonElement(el) {
    var reason = $(el).closest('.reason');
    var indeterminate = reason.find('input.state').prop('indeterminate');
    if (indeterminate) {
      reason.removeClass('adding removing');
    } else {
      var adding = reason.find('input.state').prop('checked');
      reason
        .toggleClass('adding', adding)
        .toggleClass('removing', !adding);
    }
  },

  updateStateForReasons() {
    var drba = this;
    $('*[data-reason-id]').each(function () {
      drba.updateStateForReasonId($(this).attr('data-reason-id'));
    });
  },

  updatePredictionForReasons() {
    $('.reasons-selector .reason').each(function() {
      recruitmentDispositionReasonsBulkAssignment.updatePredictionInReasonElement(this);
    });
  },

  setup() {
    let bulk = recruitmentDispositionReasonsBulkAssignment;

    var showMessage = function(div, message, duration) {
      div.find(".text").html(message);
      div.show().delay(duration).fadeOut();
    };

    // individual updating
    $(".disposition-form").on("ajax:success", function(event) {
      var jsonResponse = event.detail[0];
      showMessage($(this).find(".success"), jsonResponse.message, 1000);
    });

    $(".disposition-form").on("ajax:error", function(event) {
      var jsonResponse = event.detail[0];
      showMessage($(this).find(".error"), jsonResponse.message, 20000);
    });

    $(".disposition_reasons").select2({
      placeholder: "Type the disposition reason to get started",
      closeOnSelect: true,
      containerCssClass: "select2-container-valign-middle"
    });

    $(".disposition_reasons").on("change", function(e) {
      this.closest('form').dispatchEvent(new CustomEvent('submit', {bubbles: true, cancelable: true}));
    });

    $(".disposition_comment").on("focus", function(e) {
      $(this).data('was', $(this).val());
    }).on("blur", function(e) {
      if ($(this).data('was') == $(this).val()) { return; }
      this.closest('form').dispatchEvent(new CustomEvent('submit', {bubbles: true, cancelable: true}));
    });

    $(".previous_retention").on("change", function(e) {
      this.closest('form').dispatchEvent(new CustomEvent('submit', {bubbles: true, cancelable: true}));
    });

    $(".current_retention").on("change", function(e) {
      this.closest('form').dispatchEvent(new CustomEvent('submit', {bubbles: true, cancelable: true}));
    });

    // selecting
    $(document).on('individual-selection-changed', function(event) {
      $(event.target).closest('tr.application')
        .toggleClass('selected', event.target.checked);
    });
    $(document).on('selection-changed', function(event) {
      bulk.updateStateForReasons();
      bulk.updatePredictionForReasons();
    });

    // bulk updating reasons
    this.updateStateForReasons();

    $('select.disposition_reasons').on('change', function(event) {
      bulk.updateStateForReasons();
      bulk.updatePredictionForReasons();
    });

    $('.popup-editor .dropdown-menu').on('click', function(event) {
      if ($(event.target).is('.cancel')) return;
      event.stopPropagation();
    });

    $('form.reasons-selector, form.comment').on('submit', function (event) {
      Once.disable($(event.target).find(":submit:visible"));
    });

    $('.reasons-selector .reason input.state').on('click', function(event) {
      event.stopPropagation();
      bulk.updateStateInReasonElement(this, this.checked);
      bulk.updatePredictionInReasonElement(this);
    });

    $('.reasons-selector .reason .undo').on('click', function(event) {
      event.stopPropagation();
      bulk.updateStateInReasonElement(this);
      bulk.updatePredictionInReasonElement(this);
    });

    $('.reasons-selector').on('ajax:success', function(event) {
      $(this).find('.btn-group').removeClass('open');
      $(this).find('.once').trigger('reset.once');

      let data = event.detail[0];
      $.each(data.disposition_reason_ids_by_application_id, function(applicationId, reasonIds) {
        $('#application_' + applicationId + ' select.disposition_reasons')
          .val(reasonIds).trigger('change.select2');
      });
      bulk.updateStateForReasons();
      bulk.updatePredictionForReasons();

      showMessage($(this).find(".success"), data.message, 1000);
    });

    $('.reasons-selector').on('ajax:error', function(event) {
      let [data, status, xhr] = event.detail;
      alert(status);
      $(this).find('.once').trigger('reset.once');
    });

    $('.reasons-selector .cancel').on('click', function(event) {
      bulk.updateStateForReasons();
      bulk.updatePredictionForReasons();
    });

    $('.comment.popup-editor').on('ajax:success', function(event) {
      event.stopPropagation();

      $(this).find('.btn-group').removeClass('open');

      $(this).find('.disposition_comment_markup :input').val('');
      $('#disposition_comment_behavior_keep').click();
      $(this).find('.once').trigger('reset.once');

      let data = event.detail[0];
      $.each(data.disposition_comments || [], function(applicationId, comment) {
        var input = $('#disposition_comment_' + applicationId);
        input.val(comment.markup).trigger('change');
      });

      showMessage($(this).find(".success"), data.message, 1000);
    });

    $('.comment.popup-editor').on('ajax:error', function(event) {
      alert(event.detail[1]);
      $(this).find('.once').trigger('reset.once');
    });
  }
};
