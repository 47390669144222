export var recruitmentCopyRecruitmentOptionalFields = {
  setup: function() {
    $(document).on('show.bs.modal', '.copy-recruitment-modal', event => {
      let checkboxes = event.target.querySelectorAll('input[type=checkbox]');
      let submitbtn = event.target.querySelector('.modal-editor--submit-button');
      let recruitmentSelector = event.target.querySelector('.select2-selection__rendered');
      let recruitmentNameField = document.getElementById('copy_recruitment_editor_recruitment_name');

      function recruitmentNameFieldEmpty() {
        return recruitmentNameField.value.length === 0;
      }

      function anyChecked() {
        return event.target.querySelectorAll('input[type=checkbox]:checked').length > 0;
      }

      function toggleSubmitButton() {
        submitbtn.disabled = !anyChecked() || recruitmentNameFieldEmpty();
      }

      function setCheckboxDisabledState() {
        let disabled = isMultiLevelRecruitment();
        checkboxes.forEach(checkbox => {
          if(disabled) checkbox.checked = true;
          checkbox.disabled = disabled;
        })
      }

      function isMultiLevelRecruitment() {
        let multiLevel = false;
        let recruitmentNameEl = event.target.querySelector('.modal-content .recruitment-name');
        if(recruitmentNameEl) {
          multiLevel = recruitmentNameEl.dataset.multiLevel === 'true' ? true : false;
        }
        return multiLevel;
      }

      function setRecruitmentNameFieldValue(title) {
        recruitmentNameField.value = title
        toggleSubmitButton();
      }

      checkboxes.forEach(checkbox => {
        checkbox.addEventListener('click', () => {
          toggleSubmitButton();
          if(isMultiLevelRecruitment()) checkbox.checked = true;
        })
      })

      recruitmentNameField.addEventListener('change', toggleSubmitButton);

      if(recruitmentSelector) {
        recruitmentSelector.addEventListener('DOMSubtreeModified', () => {
          setCheckboxDisabledState();
          setRecruitmentNameFieldValue(recruitmentSelector.title)
        });
      }

      setCheckboxDisabledState();
      toggleSubmitButton();
    })
  }
}
