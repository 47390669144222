export var adminApprovalDocumentTemplatePreviewer = {
  setup: function() {
    $(document).on('show.bs.modal', '.preview-approval-document-template-modal, .template-preview-for-edit', event => {
      this.setupPreviewer(event.target);
    });
  },

  setupPreviewer(modal) {
    let form = modal.closest('form');
    let recruitmentSelector = form.querySelector('.select2-selection__rendered');
    let theFrame = form.querySelector('.template-viewer-content');
    let spinner = form.querySelector('.template-preview--spinner');

    function setPreviewURL() {
      let selectedJPF = recruitmentSelector.innerText.match(/JPF?\d+/i);

      if (selectedJPF) {
        let newUrl = theFrame.src.replace(/JPF?\d+/i, selectedJPF[0]);
        spinner.classList.remove('hidden');
        if (newUrl) { theFrame.src = newUrl }
      }
    }

    if (recruitmentSelector) { recruitmentSelector.addEventListener('DOMSubtreeModified', () => { setPreviewURL()}); }
    if (theFrame && spinner) { theFrame.onload = function () { spinner.classList.add('hidden'); }; }
  }
}
